.bb-nav {
    box-shadow: none !important;
    background-color: #fff !important;
    
    > div {
        padding: 0 60px;
        min-height: 80px;
        justify-content: space-between;
    }

    .links{
        > a {
            margin: 0 15px;
            color: #565D6DFF;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}


.bb-nav-mb {
    .logo {
        padding: 20px 0;
    }

    .links {
        margin-top: 10px;

        li {
            text-align: left;
            padding: 10px 20px;
            border-bottom: 1px solid #ddd;
        }
    }
}


@media screen and (max-width: 767px) {
    .bb-nav {
        > div {
            padding: 0 30px;
        }
    }
}